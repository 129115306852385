/* Warning: please do not statically import any dependencies here except for static assets like icons */
// import icon from './file-code-solid.svg'
// import { ReactComponent as icon } from './file-code-solid.svg'
import { EyeIcon } from "@panwds/icons";
import Registry from "@ui-platform/extensibility";
import { ControlService } from "./services/control.service";
import {
  ExtensionsConfig,
  ExtensionsExports,
  getAuthState,
  getCurrentTsgToken,
  getFrameworkServiceUrl,
  StoreState,
} from "@sparky/framework";
import {
  SPARKY_NAV3_LEFT_NAV_EXTPOINT,
  SPARKY_NAV3_REDIRECTS_EXTPOINT,
  SPARKY_NAV3_ROUTES_EXTPOINT,
} from "@sparky/framework/extpoints";
import { IOT_SECURITY_PATH, IOT_SECURITY_PATH_BASE } from "configs/const.config";
import * as pathConfig from "configs/routes.config";
/**
 *  V2 Nav
 * */

function loadZappComponents(baseUrl: string) {
  window.zapp = {
    getCurrentTsgToken: getCurrentTsgToken,
    getFrameworkServiceUrl: getFrameworkServiceUrl,
    getAuthState: getAuthState,
    assetBaseUrl: baseUrl,
  };

  const polyfill = document.createElement("script");
  polyfill.src = `${baseUrl}/zapp/polyfills.js`;
  document.head.append(polyfill);

  const main = document.createElement("script");
  main.src = `${baseUrl}/zapp/main.js`;
  main.type = "module";
  document.head.append(main);

  const panwds = document.createElement("script");
  panwds.src = `${baseUrl}/zapp/panwdsWebcomponents.js`;
  panwds.type = "module";
  document.head.append(panwds);

  const style = document.createElement("link");
  style.href = `${baseUrl}/zapp/styles.css`;
  style.rel = "stylesheet";
  document.head.append(style);
}

function removeStaticMedia(url: string): string {
  const regex = /\/static\/media\/main\.[a-zA-Z0-9]+\.js/;
  return url.replace(regex, "");
}

export default function init({ sparkyFramework, React }) {
  const isIoTAllowed = () => {
    const zingbox = ControlService.getInstance().getZingboxInstance();
    if (zingbox) {
      return ControlService.getInstance().hasIoTAccess();
    }
    return false;
  };

  const isCustomDashboardAllowed = () => {
    // return false;
    return isIoTAllowed();
  };

  const metaUrl = new URL("../zapp/main.js", import.meta.url);
  const baseUrl = removeStaticMedia(metaUrl.href);

  loadZappComponents(baseUrl);

  Registry.contributeExtension("vnr.ia.dashboards", {
    id: "iot_dashboard_oneapp",
    config: {
      title: "IoT Security",
      key: "iot_dashboard_oneapp",
      path: "/iot", // Final path for the page will be /dashboards/iot
      group: "activity", // "health" | "activity" | "posture"
      name: {
        defaultMessage: "IoT Security",
      },
      component: () => import("./containers/app/App"),
      isAllowed: () => isIoTAllowed(),
      position: ["end"],
    },
  });

  Registry.contributeExtension("external_widgets", {
    id: "iot.OverallComponent",
    config: {
      id: "iot.OverallComponent",
      title: "Overall Summary",
      description: "Overall Summary Widget",
      icon: "chart-line",
      category: "IoT Security",
      widgetSettings: [],
      layoutConfig: {
        h: 10,
        w: 12,
        x: 0,
      },
      component: React.lazy(() => import("./containers/main/CustomDashboardWidgetWrapper")),
      enabled: () => isCustomDashboardAllowed(),
    },
  });

  Registry.contributeExtension("external_widgets", {
    id: "iot.DevicesComponent",
    config: {
      id: "iot.DevicesComponent",
      title: "Devices Distribution",
      description: "Devices Distribution Widget",
      icon: "chart-line",
      category: "IoT Security",
      widgetSettings: [],
      layoutConfig: {
        h: 20,
        w: 12,
        x: 0,
      },
      component: React.lazy(() => import("./containers/main/CustomDashboardWidgetWrapper")),
      enabled: () => isCustomDashboardAllowed(),
    },
  });

  Registry.contributeExtension("external_widgets", {
    id: "iot.TopOSComponent",
    config: {
      id: "iot.TopOSComponent",
      title: "Top Operating System",
      description: "Top OS Component Widget",
      icon: "chart-line",
      category: "IoT Security",
      widgetSettings: [],
      layoutConfig: {
        h: 20,
        w: 4,
        x: 0,
      },
      component: React.lazy(() => import("./containers/main/CustomDashboardWidgetWrapper")),
      enabled: () => isCustomDashboardAllowed(),
    },
  });

  Registry.contributeExtension("external_widgets", {
    id: "iot.SubnetComponent",
    config: {
      id: "iot.SubnetComponent",
      title: "Network Distribution",
      description: "Network Distribution Widget",
      icon: "chart-line",
      category: "IoT Security",
      widgetSettings: [],
      layoutConfig: {
        h: 20,
        w: 8,
        x: 0,
      },
      component: React.lazy(() => import("./containers/main/CustomDashboardWidgetWrapper")),
      enabled: () => isCustomDashboardAllowed(),
    },
  });

  return function tsgLoad() {
    return {
      navigation: [
        {
          key: "iot",
          path: "/iot",
          noRBACheck: true,
          exact: true,
          hidden: true,
          component: () => import("./containers/app/App"),
        },
        {
          key: "insights",
          title: "Insights",
          expandable: true,
          path: "/insights",
          noRBACheck: true,
          exact: false,
          icon: EyeIcon, // icon
          mergeNav: true, // required if the first level is shared
          children: [
            {
              key: "assets",
              title: "IoT Security",
              path: "/insights/iot-security",
              noRBACheck: true,
              avail: () => isIoTAllowed(),
              contentClassName: "panwds-tw3", // contentClassName should always besides the component, panwds-tw3 is needed for PanWDS
              component: () => import("./containers/app/App"),
            },
          ],
        },
      ],
    };
  };
}

/**
 *  V3 Nav
 *  Lazy removed due to lazy autodetect & noRBACheck not needed
 * */

export const extensions: ExtensionsExports = ({ sparkyFramework }) => {
  const isIoTAllowed = () => {
    const zingbox = ControlService.getInstance().getZingboxInstance();
    if (zingbox) {
      return ControlService.getInstance().hasIoTAccess();
    }
    return false;
  };

  const leftNavExt = {
    point: SPARKY_NAV3_LEFT_NAV_EXTPOINT,
    configs: [
      {
        id: "sparky.nav.left.iot",
        config: {
          stateLoaders: ["auth", "main"],
          navigation: () => {
            return [
              {
                key: "insights",
                title: "Insights",
                expandable: true,
                path: IOT_SECURITY_PATH_BASE,
                children: [
                  {
                    key: "security-insights",
                    path: IOT_SECURITY_PATH_BASE + "/security",
                    title: "Security",
                    children: [
                      {
                        title: "IoT Security",
                        path: IOT_SECURITY_PATH + "/dashboard/security",
                        avail: () => isIoTAllowed(),
                      },
                    ],
                  },
                ],
              },
            ];
          },
        },
      },
    ],
  } as ExtensionsConfig;

  // Build base route
  const routeObject = (path: string) => {
    const route = {
      id: "iot_dashboard_oneapp-" + path,
      config: {
        path: IOT_SECURITY_PATH + path,
        stateLoaders: ["auth", "main"],
        exact: false,
        mapStateToProps: (state: StoreState) => state,
        component: () => import("./containers/app/App"),
        isAllowed: () => isIoTAllowed(),
        position: ["end"],
      },
    };

    return route;
  };

  // Redirect route
  const redirectObject = ({ path, redirectPath }) => {
    return {
      id: "iot_dashboard_oneapp-" + path,
      config: {
        path: path,
        redirectTo: redirectPath,
        preserveSearch: true,
      },
    };
  };

  const routes = pathConfig.ROUTES.map((route) => routeObject(route));
  const redirects = pathConfig.REDIRECTS.map((route) => redirectObject(route));

  const nav3RedirectExt = {
    point: SPARKY_NAV3_REDIRECTS_EXTPOINT,
    configs: redirects,
  } as ExtensionsConfig;

  const routesExt = {
    point: SPARKY_NAV3_ROUTES_EXTPOINT,
    configs: routes,
  } as ExtensionsConfig;

  return [leftNavExt, routesExt, nav3RedirectExt];
};
// /insights/iot-security/dashboard/security
