import { IOT_SECURITY_PATH } from "./const.config";

export const ROUTES = [
  "/dashboard",
  "/dashboard/security",
  "/dashboard/biomed",
  "/dashboard/utilization",
  "/dashboard/custom",
  "/dashboard/custom/mod/:dashboardId",
  "/assets",
  "/assets/assets",
  "/assets/assets/overview",
  "/assets/assets/overview/:id",
  "/assets/assets/ipendpoint",
  "/assets/assets/staticip",
  "/assets/assets/multi-interfaces",
  "/assets/location",
  "/assets/profiles",
  "/assets/profiles/detail",
  "/assets/profiles/detail/overview",
  "/assets/categories",
  "/assets/categories/:category",
  "/assets/device-files",
  "/network",
  "/network/network",
  "/network/network/subnets",
  "/network/network/segments-table",
  "/network/network/sites",
  "/network/applications",
  "/alerts",
  "/alerts/security-alerts",
  "/alerts/security-alerts/overview",
  "/alerts/security-alerts/alerts",
  "/alerts/security-alerts/alerts/list",
  "/alerts/security-alerts/alert-detail",
  "/alerts/security-alerts/suppression",
  "/alerts/custom-alerts",
  "/alerts/custom-alerts/list",
  "/alerts/custom-alerts/new",
  "/alerts/custom-alerts/:id/view",
  "/alerts/custom-alerts/:id/edit",
  "/alerts/custom-alerts/:id/clone",
  "/vulnerabilities",
  "/vulnerabilities/vulnerabilities",
  "/vulnerabilities/vulnerabilities/overview",
  "/vulnerabilities/vulnerabilities/vulnerabilities",
  "/vulnerabilities/vulnerabilities/vulnerabilitySetting",
  "/vulnerabilities/vulnerabilities/detail",
  "/vulnerabilities/recall",
  "/vulnerabilities/mds2",
  "/vulnerabilities/mds2/all",
  "/policy",
  "/policy/set",
  "/policy/setting",
  "/integrations",
  "/integrations/xsoar-integration",
  "/integrations/cloud-identity-engine",
  "/report",
  "/report/deviceTrafficLogs",
  "/report/auditlogs",
  "/report/report",
  "/report/report/reports",
  "/report/report/reports/:reportName",
  "/report/report/vulnerabilityscan",
  "/settings",
  "/settings/attributes",
  "/settings/attributes/system",
  "/settings/attributes/user",
  "/settings/scanners",
  "/settings/tagmanagement",
  "/settings/tagmanagement/system",
  "/settings/tagmanagement/custom",
  "/settings/riskScoreConfig",
  "/administration",
  "/administration/custom-dashboards",
  "/administration/custom-dashboards/list",
  "/administration/custom-dashboards/create",
  "/administration/custom-dashboards/edit/:dashboardId",
  "/administration/custom-widgets",
  "/administration/custom-widgets/list",
  "/administration/custom-widgets/create",
  "/administration/custom-widgets/edit/:widgetId",
  "/administration/license",
  "/administration/firewalls",
  "/administration/firewalls/firewalls",
  "/administration/otp-psk",
  "/administration/onDemandPcap",
  "/administration/notifications",
  "/administration/notifications/custom-alert",
  "/administration/notifications/security-alerts",
  "/administration/notifications/system-events",
  "/administration/notifications/new",
  "/administration/notifications/:id/view",
  "/administration/notifications/:id/edit",
  "/administration/systemalerts",
  "/administration/query-library",
  "/administration/query-library/list",
  "/administration/query-library/create",
  "/security-policy",
  "/vulnerabilities/detail/:vulnerabilityName",
  "/profile-behaviors",
  "/administration/data-quality/basic",
  "/administration/data-quality/basic/:id",
  "/administration/data-quality/low-inventory/list",
  "/administration/data-quality/low-inventory/add",
  "/administration/data-quality/low-inventory/flow-chart",
  "/administration/data-quality/missing-device/list",
  "/administration/data-quality/missing-device/add",
  "/administration/data-quality/missing-device/critical",
  "/administration/data-quality/missing-device/neighbor",
  "/administration/data-quality/missing-device/matching",
  "/administration/data-quality/missing-device/common",
  "/administration/data-quality/missing-device/all-device",
  "/administration/data-quality/missing-device/flow-chart",
];

export const DEFAULT_ROUTE = "dashboard";

export const REDIRECTS = [
  {
    path: `/security-insights/dashboards/iot`,
    redirectPath: `${IOT_SECURITY_PATH}/${DEFAULT_ROUTE}`,
  },
  {
    path: `${IOT_SECURITY_PATH}/dashboard`,
    redirectPath: `${IOT_SECURITY_PATH}/dashboard/security`,
  },
  {
    path: `${IOT_SECURITY_PATH}/assets`,
    redirectPath: `${IOT_SECURITY_PATH}/assets/assets`,
  },
  {
    path: `${IOT_SECURITY_PATH}/network`,
    redirectPath: `${IOT_SECURITY_PATH}/network/network`,
  },
  {
    path: `${IOT_SECURITY_PATH}/alerts`,
    redirectPath: `${IOT_SECURITY_PATH}/alerts/security-alerts`,
  },
  {
    path: `${IOT_SECURITY_PATH}/vulnerabilities`,
    redirectPath: `${IOT_SECURITY_PATH}/vulnerabilities/vulnerabilities`,
  },
  {
    path: `${IOT_SECURITY_PATH}/policy`,
    redirectPath: `${IOT_SECURITY_PATH}/policy/set`,
  },
  {
    path: `${IOT_SECURITY_PATH}/integrations`,
    redirectPath: `${IOT_SECURITY_PATH}/integrations/xsoar-integration`,
  },
  {
    path: `${IOT_SECURITY_PATH}/report`,
    redirectPath: `${IOT_SECURITY_PATH}/report/deviceTrafficLogs`,
  },
  {
    path: `${IOT_SECURITY_PATH}/settings`,
    redirectPath: `${IOT_SECURITY_PATH}/settings/attributes`,
  },
  {
    path: `${IOT_SECURITY_PATH}/administration`,
    redirectPath: `${IOT_SECURITY_PATH}/administration/custom-dashboards`,
  },
  {
    path: `${IOT_SECURITY_PATH}/dashboard/custom`,
    redirectPath: `${IOT_SECURITY_PATH}/dashboard/custom/mod/:dashboardId`,
  },
  {
    path: `${IOT_SECURITY_PATH}/assets/assets`,
    redirectPath: `${IOT_SECURITY_PATH}/assets/assets/overview`,
  },
  {
    path: `${IOT_SECURITY_PATH}/assets/profiles/detail`,
    redirectPath: `${IOT_SECURITY_PATH}/assets/profiles/detail/overview`,
  },
  {
    path: `${IOT_SECURITY_PATH}/network/network`,
    redirectPath: `${IOT_SECURITY_PATH}/network/network/subnets`,
  },
  {
    path: `${IOT_SECURITY_PATH}/alerts/security-alerts`,
    redirectPath: `${IOT_SECURITY_PATH}/alerts/security-alerts/overview`,
  },
  {
    path: `${IOT_SECURITY_PATH}/alerts/custom-alerts`,
    redirectPath: `${IOT_SECURITY_PATH}/alerts/custom-alerts/list`,
  },
  // {
  //     'path': `${IOT_SECURITY_PATH}/vulnerabilities/vulnerabilities`,
  //     'redirectPath': `${IOT_SECURITY_PATH}/vulnerabilities/vulnerabilities/overview`
  // },
  {
    path: `${IOT_SECURITY_PATH}/report/report`,
    redirectPath: `${IOT_SECURITY_PATH}/report/report/reports`,
  },
  {
    path: `${IOT_SECURITY_PATH}/settings/attributes`,
    redirectPath: `${IOT_SECURITY_PATH}/settings/attributes/system`,
  },
  {
    path: `${IOT_SECURITY_PATH}/settings/tagmanagement`,
    redirectPath: `${IOT_SECURITY_PATH}/settings/tagmanagement/system`,
  },
  {
    path: `${IOT_SECURITY_PATH}/administration/custom-dashboards`,
    redirectPath: `${IOT_SECURITY_PATH}/administration/custom-dashboards/list`,
  },
  {
    path: `${IOT_SECURITY_PATH}/administration/custom-widgets`,
    redirectPath: `${IOT_SECURITY_PATH}/administration/custom-widgets/list`,
  },
  {
    path: `${IOT_SECURITY_PATH}/administration/query-library`,
    redirectPath: `${IOT_SECURITY_PATH}/administration/query-library/list`,
  },
  {
    path: `${IOT_SECURITY_PATH}/administration/firewalls`,
    redirectPath: `${IOT_SECURITY_PATH}/administration/firewalls/firewalls`,
  },
  {
    path: `${IOT_SECURITY_PATH}/administration/notifications`,
    redirectPath: `${IOT_SECURITY_PATH}/administration/notifications/custom-alert`,
  },
  {
    path: `${IOT_SECURITY_PATH}/alerts/security-alerts/alerts`,
    redirectPath: `${IOT_SECURITY_PATH}/alerts/security-alerts/alerts/list`,
  },
];
