import { Instance, getAuthState } from "@sparky/framework";

export class ControlService {
  private static instance: ControlService;

  private constructor() {}

  public static getInstance(): ControlService {
    if (!ControlService.instance) {
      ControlService.instance = new ControlService();
    }
    return ControlService.instance;
  }

  public getZingboxInstance(): Instance {
    const authState = getAuthState();
    if (authState && authState.instances) {
      const zingbox = authState.instances.find((item) => item.app_id === "zingbox" || item.app_id === "iotx");
      return zingbox;
    }
    return undefined;
  }

  public getExternalURL(): string {
    const zingbox = ControlService.getInstance().getZingboxInstance();
    if (zingbox) {
      const url = zingbox.url;
      return url;
    }
    return "";
  }

  public isSuperUser(): boolean {
    const authState = getAuthState();
    if (authState && authState.access) {
      const allApps = authState.access.find((app) => app.app_id === "");
      if (allApps !== undefined) {
        if (allApps.aggRoleNames.includes("superuser") || allApps.aggRoleNames.includes("msp_superuser")) {
          return true;
        }
      }

      const zingbox = authState.access.find((app) => app.app_id === "zingbox" || app.app_id === "iotx");
      return zingbox !== undefined;
    }
    return false;
  }

  public getUserEmail() {
    const authState = getAuthState();

    return authState?.user?.email;
  }

  public getUserInfo() {
    const authState = getAuthState();

    return authState?.user;
  }

  public hasIoTAccess(): boolean {
    const authState = getAuthState();
    if (authState && authState.access) {
      const allApps = authState.access.find((app) => app.app_id === "");
      if (allApps !== undefined) {
        if (
          allApps.aggRoleNames.includes("superuser") ||
          allApps.aggRoleNames.includes("msp_superuser") ||
          allApps.aggRoleNames.includes("view_only_admin")
        ) {
          return true;
        }
      }

      const zingbox = authState.access.find((app) => app.app_id === "zingbox" || app.app_id === "iotx");
      return zingbox !== undefined;
    }
    return false;
  }
}
